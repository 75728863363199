/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode, useEffect, useState } from 'react';
import { Header, Sidebar, Toolbar, Content, Footer } from '@components/modules';
import { UrlObject } from 'url';
import { AuthContextProvider, AuthInit } from '@context/AuthContext';
import useMediaQuery from '@utils/useMediaQuery';
import { StyleMainContainer } from './style';

const MainContainer: React.FC<{
  children?: React.ReactNode;
  breadcrumb?: { title: string; href: string | UrlObject }[] | null;
  title?: string;
  subTitle?: string;
  extra?: ReactNode;
}> = ({ children, breadcrumb, title, subTitle, extra }) => {
  const isBreakpoint = useMediaQuery(992);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    document.body.style.backgroundImage = `none`;
    if (isBreakpoint === true) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  }, []);

  return (
    <AuthContextProvider>
      <AuthInit>
        <StyleMainContainer className="flex flex-col flex-root app-root" id="app_root">
          <div
            className="app-page flex flex-col"
            id="app_page"
          >
            <Header isToggle={isActive} toggleSidebar={() => setIsActive(!isActive)} />
            <div
              className={`app-wrapper flex flex-col flex-row-fluid`}
              id="app_wrapper"
              style={{
                transition: 'padding-left 0.3s ease, padding-right 0.3s ease',
                paddingTop: '70px',
                paddingLeft: isBreakpoint === true ? '0px' : isBreakpoint === false && isActive === true ? 'calc(265px + 0px + 0px)' : isBreakpoint === false && isActive === false ? 'calc(75px + 0px + 0px)' : '0px', // NOSONAR
              }}
            >
              <Sidebar isActive={isActive} setIsActive={() => setIsActive(!isActive)} />
              <div
                className="app-main flex flex-col flex-row-fluid"
                id="app_main"
                style={{
                  transition: 'margin 0.3s ease',
                }}
              >
                <div className="flex flex-col">
                  <Toolbar title={title} subTitle={subTitle} breadcrumb={breadcrumb} extra={extra} />
                  <Content>
                    <div className="card mb-5 mb-xl-10">{children}</div>
                  </Content>
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </StyleMainContainer>
      </AuthInit>
    </AuthContextProvider>
  );
};

export default MainContainer;
