import Link from 'next/link';
import {StyleFooter} from './style';

const Footer = () => {
  return (
    <StyleFooter id="app_footer" className="bg-white shadow py-4 md:py-0">
      <div className="app-container w-full text-center md:flex md:flex-col lg:flex-row md:items-center md:justify-between">
        <div className="w-full mx-auto md:flex md:items-center md:justify-between">
          <span className="text-sm text-gray-500 sm:text-center order-2 md:order-1">
            &copy; {new Date().getFullYear().toString()}
            <Link href="/" className="hover:underline ml-2">
              Samathi101
            </Link>
            . All Rights Reserved.
          </span>
        </div>
      </div>
    </StyleFooter>
  );
};

export default Footer;
