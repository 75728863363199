/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import useMediaQuery from '@utils/useMediaQuery';
import { Avatar, Dropdown } from '@components/elements';
import Swal from 'sweetalert2';
import { useAuthContext } from '@/context';
import Link from 'next/link';
import UserService from '@/services/users-services/UserServices';

const defaultAvatar = "https://storage.googleapis.com/will-power-dev-57d91.appspot.com/public/img/banner/jaoe38c1lo800rzs.webp";

type HeaderProps = {
  toggleSidebar: () => void;
  isToggle: boolean;
};

const Header = ({ toggleSidebar, isToggle }: HeaderProps) => { // NOSONAR
  const { logout, saveUser, auth, imgProfile, userToken } = useAuthContext();
  const isBreakpoint = useMediaQuery(992);
  const [userAvatar, setUserAvatar] = useState<string>(defaultAvatar);
  const [username, setUsername] = useState<string>('');

  const funLogout = async () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Log out!',
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (result.isConfirmed) {
          logout();
        }
      }
    });
  };

  const fetchData = async (id: any) => {
    UserService.getUserById(auth, id)
      .then(async (res) => {
        let avatar = '';
        const dat = res.data;
        if (res?.data?.avatar !== null) {
          avatar = await UserService.signImg(auth, res?.data?.avatar);
        }
        if (dat) {
          setUserAvatar(avatar || defaultAvatar);
        }
      });
  };

  useEffect(() => {
    if (!userToken) {
      setUserAvatar('');
    } else {
      const userParse = JSON.parse(saveUser);
      fetchData(userParse?.id);
      setUsername(userParse?.full_name || '');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, saveUser, imgProfile, userToken]);

  return (
    <div
      className={`z-[100] w-full fixed flex items-stretch lef-0 right-0 top-0 shadow-md`}
      style={{
        height: '70px',
        // left: isToggle === true ? 'calc(265px + 0px + 0px)' : 'calc(75px + 0px + 0px)',
        left:
          isBreakpoint === true // NOSONAR
            ? '0px' // NOSONAR
            : isBreakpoint === false && isToggle === true // NOSONAR
            ? 'calc(265px + 0px + 0px)' // NOSONAR
            : isBreakpoint === false && isToggle === false // NOSONAR
            ? 'calc(75px + 0px + 0px)' // NOSONAR
            : '0px', // NOSONAR
      }}
    >
      <div
        className="flex justify-between items-center bg-white z-[1] shadow-md flex-shrink-0 px-4"
        style={{
          width:
            isBreakpoint === true // NOSONAR
              ? '100%' // NOSONAR
              : isBreakpoint === false && isToggle === true // NOSONAR
              ? 'calc(100% - 265px)' // NOSONAR
              : isBreakpoint === false && isToggle === false // NOSONAR
              ? 'calc(100% - 75px)' // NOSONAR
              : '100%', // NOSONAR
        }}
      >
        <div className="">
          <label // NOSONAR
            aria-hidden="true"
            htmlFor="left-sidebar-drawer"
            className="btn btn-white drawer-button lg:hidden"
            onClick={toggleSidebar}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              aria-hidden="true"
              className="h-5 inline-block w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </label>
          {isBreakpoint === false ? (
            <div
              id="app_sidebar_toggle"
              aria-hidden="true"
              className="app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted bg-white hover:bg-white hover:text-primary h-[40px] w-[40px] position-absolute top-50 start-100 translate-middle rotate -ml-8"
              onClick={toggleSidebar}
            >
              <span
                className={`svg-icon svg-icon-2 transform-gpu ${
                  isToggle === true ? 'rotate-0' : 'rotate-180'
                }`}
              >
                <svg
                  className=""
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.5"
                    d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z"
                    fill="currentColor"
                  />
                  <path
                    d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="order-last space-x-8">
          <Dropdown
            trigger={
              <div className="avatar-wrap cursor-pointer rounded"> 
                {userAvatar && <Avatar alt={username} shape="rounded" src={userAvatar} size="md" />}
              </div>
            }
            content={
              <ul className="flex flex-col">
                <li aria-hidden="true" className='dropdown-item text-center font-semibold' onClick={() => funLogout()}>
                  <Link href="">
                    Logout
                  </Link>
                </li>
              </ul>
            }
            placement="bottomRight"
            animation={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
