import styled from 'styled-components';

export const StyleSidebarMenu = styled.div`
  --padding-top-logo: 36px;
  --padding-bottom-logo: 36px;
  position: relative;
  margin-top: 16px;
  margin-bottom: 36px;
  flex: 1 0 auto;
  .app-sidebar-wrapper {
    height: calc(100vh - 52px - var(--padding-top-logo) - var(--padding-bottom-logo) - 24px);
  }
  .menu {
    > .menu-item {
      margin-left: 0.115rem;
      .menu-heading {
        color: #FFFFFF;
      }
      .menu-heading-subtitle {
        font-size: 11px;
        color: #F4F6F9;
        letter-spacing: 0.06px;
        font-weight: 500;
        line-height: 1.5;
      }
    }
    .menu-item {
      display: block;
      padding: 0.15rem 0;
    }
    .menu-content {
      padding: 0.65rem 1rem;
      padding-top: 1.25rem;
    }
    .menu-link {
      cursor: pointer;
      display: flex;
      align-items: center;
      flex: 0 0 100%;
      transition: none;
      outline: none !important;
    }
  }
`;