import styled from 'styled-components';

export const StyleMainContainer = styled.div`
  background-color: #f5f8fa;
  .app-main {
    display: flex;
    @media only screen and (max-width: 991.98px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .app-page {
    min-height: 100vh;
  }
  .app-container {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
`;