import styled from 'styled-components';

export const StyleSidebarMenuItem = styled.div<{ hidetitle: string }>`
  display: block;
  padding: 0.15rem 0;
  .menu-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    flex: 0 0 100%;
    transition: none;
    outline: none !important;
  }
  .menu-link {
    color: #FFFFFF;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    .menu-icon {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      margin-right: 0.5rem;
    }
    &.active {
      transition: color 0.2s ease;
      // background-color: #2A2A3C;
      color: #FFB948;
    }
  }
  .menu-rounded .menu-link {
    border-radius: 0.475rem;
  }
  .tooltip-menu {
    display: inline-block;
    position: absolute;
    background-color: #313443;
    padding: 8px 15px;
    border-radius: 3px;
    margin-top: -26px;
    left: 90px;
    opacity: 0;
    visibility: hidden;
    font-size: 13px;
    letter-spacing: .5px;
    color: #ffffff;
    @media only screen and (min-width: 992px) {
      white-space: nowrap;
    }
    
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: -4px;
      top: 10px;
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
      background-color: inherit;
    }
  }
  &:hover {
    .tooltip-menu {
      visibility: ${props => props.hidetitle === "false" ? 'visible' : 'hidden'};
      opacity: 1;
    }
  }
  
  &.active {
    i {
      color: #98D7EC;
    }
  }
`;