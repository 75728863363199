/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/jsx-no-duplicate-props */
import Link from 'next/link';
import React, { ReactNode } from 'react';
import { UrlObject } from 'url';
import { v4 as uuidv4 } from 'uuid';
import { StyleToolbar } from './style';

export interface ToolbarPropsType {
  title?: string,
  subTitle?: string, // NOSONAR
  breadcrumb?: { title: string; href: string | UrlObject; }[] | null | undefined;  // NOSONAR
  extra?: ReactNode;
};

const renderTitle = (props: ToolbarPropsType) => {
  const { title, subTitle, breadcrumb } = props;
  if (title || subTitle) {
    return (
      <div className="page-heading">
        {title && <h1 className="flex text-dark-80 font-bold fs-3 flex-col justify-center my-0" data-testid="title">{title}</h1>}
        {subTitle && <span className="sub-title flex">{subTitle}</span>}
        {breadcrumb && breadcrumb !== null && breadcrumb.length > 0 ? (
          <ul className="breadcrumb breadcrumb-separatorless text-sm my-0">
            {breadcrumb.length > 0 && breadcrumb.map((item: { title: boolean | React.ReactElement<unknown, string | React.JSXElementConstructor<unknown>> | React.ReactFragment | React.Key | null | undefined, href: string | UrlObject }, index) => {
              const isLast = index === breadcrumb.length - 1;
              if (isLast) {
                return (
                  <li className="breadcrumb-item" key={uuidv4()}>{item.title?.toString()}</li>
                );
              }
              return (
                <li className="breadcrumb-item" key={uuidv4()}>
                  <Link href={item.href}>{item.title?.toString()}</Link>
                </li>
              );
              })}
          </ul>
        ) : (
          ""
        )}
      </div>
    );
  }
  return null;
};

const Toolbar = (props: ToolbarPropsType) => {
  const { title, extra } = props;
  return (
    <StyleToolbar id='app_toolbar' className={`app-toolbar ${title ? ' py-3 lg:py-6 ': ''}`}>
      <div id='app_toolbar_container' className={`app-container w-full flex ${extra !== undefined && extra !== null ? ' justify-between items-center' :''}`}>
        {renderTitle(props)}
        {extra && <div className="app_toolbar_extra">{extra}</div>}
      </div>
    </StyleToolbar>
  );
};

export default Toolbar;
