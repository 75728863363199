/* eslint-disable @next/next/no-img-element */
import { useRef } from 'react';
import { SidebarMenu } from '@components/modules';
import useMediaQuery from '@utils/useMediaQuery';
import {StyleSidebar } from './style';

const Sidebar: React.FC<{
  classNameWrap?: string;
  className?: string;
  children?: React.ReactNode;
  header?: boolean | React.ReactNode | null;
  title?: string;
  extra?: React.ReactNode;
  footer?: boolean | React.ReactNode | null;
  scroll?: boolean;
  bodyHeight?: number;
  borderNone?: boolean;
  isActive: boolean;
  // eslint-disable-next-line no-unused-vars
  setIsActive: (isActive: boolean) => void;
}> = ({ isActive, setIsActive }) => {
  const sidebarRef = useRef<HTMLDivElement>(null);
  const isBreakpoint = useMediaQuery(992);

  return (
    <>
      <StyleSidebar
        ref={sidebarRef}
        id="app_sidebar"
        className={`${isActive ? 'flex' : ''} bg-primary `}
        style={{
          flexShrink: 0,
          width: isActive === true && isBreakpoint === false ? '265px' : isActive === false && isBreakpoint === false ? '75px' : '265px', // NOSONAR
          left: isBreakpoint === true && isActive === false ? '-265px' : '0px',
          zIndex: isBreakpoint === true ? 105 : 1,
        }}
      >
        <div
          className="app-sidebar-logo gap-4"
          style={{
            paddingLeft: isActive === true ? '15px' : '24px',
            paddingRight: isActive === true ? '15px' : '24px',
          }}
        >
          {/* <Link href='/dashboard'>
            {isBreakpoint ? (
              <img
                alt='Logo'
                src='/logotwin-26.png'
                className="w-[26px] app-sidebar-logo-minimize"
              />
            ) : (
              <img
                alt='Logo'
                src='/logotwin-26.png'
                className="w-[26px] app-sidebar-logo-default"
              />
            )}
          </Link> */}
          {isActive === true ? <span className="" data-testid="navbar-title">Samathi101</span> : null}
        </div>
        <SidebarMenu isActive={isActive} setIsActive={setIsActive} />
      </StyleSidebar>
      {isBreakpoint === true && isActive === true && (
        <div
          aria-hidden="true"
          className="app-sidebar-overlay" 
          onClick={() => setIsActive(false)}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            overflow: 'hidden',
            zIndex: 2,
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            animation: 'animation-drawer-fade-in 0.3s ease-in-out 1',
          }}
        />
      )}
    </>
  );
};

export default Sidebar;
