import styled from 'styled-components';

export const StyleSidebarMenuItemWithSub = styled.div`
  display: block;
  padding: 0.15rem 0;
  .menu-arrow {
    display: flex;
    align-items: stretch;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    margin-left: auto;
    width: 2rem;
    height: 10px;
    &:after {
      display: block;
      width: 100%;
      content: " ";
      will-change: transform;
      transition: transform 0.3s ease;
      transform: rotateZ(90deg);
      background-size: 100% 100%;
      mask-repeat: no-repeat;
      mask-position: center;
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-position: center;
      background-color: #FFFFFF;
      -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 6' fill='var%28--bs-text-muted%29'%3e%3cpath d='M2.72011 2.76429L4.46358 1.02083C4.63618 0.848244 4.63617 0.568419 4.46358 0.395831C4.29099 0.223244 4.01118 0.223244 3.83861 0.395831L1.52904 2.70537C1.36629 2.86808 1.36629 3.13191 1.52904 3.29462L3.83861 5.60419C4.01117 5.77675 4.29099 5.77675 4.46358 5.60419C4.63617 5.43156 4.63617 5.15175 4.46358 4.97919L2.72011 3.23571C2.58994 3.10554 2.58994 2.89446 2.72011 2.76429Z'/%3e%3c/svg%3e");
      mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 6' fill='var%28--bs-text-muted%29'%3e%3cpath d='M2.72011 2.76429L4.46358 1.02083C4.63618 0.848244 4.63617 0.568419 4.46358 0.395831C4.29099 0.223244 4.01118 0.223244 3.83861 0.395831L1.52904 2.70537C1.36629 2.86808 1.36629 3.13191 1.52904 3.29462L3.83861 5.60419C4.01117 5.77675 4.29099 5.77675 4.46358 5.60419C4.63617 5.43156 4.63617 5.15175 4.46358 4.97919L2.72011 3.23571C2.58994 3.10554 2.58994 2.89446 2.72011 2.76429Z'/%3e%3c/svg%3e");
      mask-repeat: no-repeat;
      mask-position: center;
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-position: center;
      background-color: #FFFFFF;
      -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 6' fill='var%28--bs-text-muted%29'%3e%3cpath d='M3.27989 3.23571L1.53642 4.97917C1.36382 5.15176 1.36383 5.43158 1.53642 5.60417C1.70901 5.77676 1.98882 5.77676 2.16139 5.60417L4.47096 3.29463C4.63371 3.13192 4.63371 2.86809 4.47096 2.70538L2.16139 0.395812C1.98883 0.22325 1.70901 0.22325 1.53642 0.395812C1.36383 0.568437 1.36383 0.84825 1.53642 1.02081L3.27989 2.76429C3.41006 2.89446 3.41006 3.10554 3.27989 3.23571Z'/%3e%3c/svg%3e");
      mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 6' fill='var%28--bs-text-muted%29'%3e%3cpath d='M3.27989 3.23571L1.53642 4.97917C1.36382 5.15176 1.36383 5.43158 1.53642 5.60417C1.70901 5.77676 1.98882 5.77676 2.16139 5.60417L4.47096 3.29463C4.63371 3.13192 4.63371 2.86809 4.47096 2.70538L2.16139 0.395812C1.98883 0.22325 1.70901 0.22325 1.53642 0.395812C1.36383 0.568437 1.36383 0.84825 1.53642 1.02081L3.27989 2.76429C3.41006 2.89446 3.41006 3.10554 3.27989 3.23571Z'/%3e%3c/svg%3e");
    }
  }
  .menu-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    flex: 0 0 100%;
    transition: none;
    outline: none !important;
  }
  .menu-link {
    color: #FFFFFF;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    .menu-icon {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      margin-right: 0.5rem;
    }
  }
  &.active {
    transition: color 0.2s ease;
    // background-color: #2A2A3C;
    color: #FFFFFF;
    border-radius: 0.475rem;
    .menu-arrow {
      &:after {
        transform: rotateZ(-90deg);
        transition: transform 0.3s ease;
      }
    }
  }
  .menu-bullet {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8px;
    margin-right: 1.625rem;
    .bullet {
      background-color: #FFFFFF;
    }
  }
  .menu-sub {
    padding: 0.65rem 1rem;
    color: #FFFFFF;
    display: none;
    transition: top 0.3s ease 0s;
    &.menu-sub-accordion {
      .menu-link {
        color: #FFFFFF;
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
        padding-left: 1.625rem;
        padding-right: 0.625rem;
        &.active {
          color: #FFB948;
          .menu-bullet {
            .bullet {
              background-color: #FFB948;
            }
          }
        }
      }
      &.active {
        display: block;
      }
      a {
        color: #FFFFFF;
      }
    }
  }
  .menu-rounded {
    .menu-link {
      border-radius: 0.475rem;
    }
  }
  .bullet {
    display: inline-block;
    background-color: #B5B5C3;
    border-radius: 6px;
    width: 8px;
    height: 8px;
    flex-shrink: 0;
  }
  .bullet-dot {
    width: 8px;
    height: 8px;
    border-radius: 100% !important;
  }
  .tooltip-menu {
    display: inline-block;
    position: absolute;
    background-color: #313443;
    padding: 8px 15px;
    border-radius: 3px;
    margin-top: 0px;
    left: 90px;
    opacity: 0;
    visibility: hidden;
    font-size: 13px;
    letter-spacing: .5px;
    color: #ffffff;
    @media only screen and (min-width: 992px) {
      white-space: nowrap;
    }
    
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: -4px;
      top: 10px;
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
      background-color: inherit;
    }
  }
  &.menu-tooltip {
    visibility: visible;
    opacity: 1;
    &.active {
      color: #FFB948;
      .menu-icon {
        svg, i {
          color: #FFB948;
        }
      }
    }
    &:hover {
      .tooltip-menu {
        visibility: visible;
        opacity: 1;
      }
    }
    .menu-sub-accordion {
      &.active {
        display: none;
      }
    }
  }
  &.active {
    i {
      color: #98D7EC;
    }
  }
`;