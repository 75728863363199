/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames';
import React, { useState } from 'react';

interface TabsProps {
  children: React.ReactNode;
  rootClassName?: string;
  tabType?: 'lifted' | 'bordered' | 'boxed';
  sizes?: 'xs' | 'sm' | 'md' | 'lg';
  activeTab?: number;
}

const Tabs: React.FC<TabsProps> = ({ rootClassName, children, tabType, sizes = 'md', activeTab = 0 }) => {
  const [active, setActive] = useState<number>(activeTab);

  const handleTabClick = (index: number) => {
    setActive(index);
  };

  const btnCls = classNames('tab', {
    'tab-lifted': tabType === 'lifted',
    'tab-bordered': tabType === 'bordered',
    'tab-boxed': tabType === 'boxed',
    'tab-xs': sizes === 'xs',
    'tab-sm': sizes === 'sm',
    'tab-md': sizes === 'md',
    'tab-lg': sizes === 'lg',
  }, rootClassName);

  return (
    <>
      <ul className="tabs">
        {React.Children.map(children, (child, index) => {
          if (React.isValidElement(child)) {
            const { label } = child.props;
            const tabsKey = child.props.label.toString().toLowerCase().replace(' ', '-');
            return (
              <li
                key={tabsKey}
                aria-hidden="true"
                className={classNames(btnCls, {
                  [`tab-active`]: active === index,
                })}
                onClick={() => handleTabClick(index)}
              >
                {label}
              </li>
            );
          }
          return null;
        })}
      </ul>
      {React.Children.toArray(children)[active]}
    </>
  );
};

interface TabProps {
  label: string;
  children: React.ReactNode;
  className?: string;
  active?: boolean;
}

const Tab: React.FC<TabProps> = ({ label, children, className, active }) => {
  return <div className={classNames('tab-content',{"block": active}, className)}>{children}</div>;
};

export { Tabs, Tab };
