import styled from 'styled-components';

export const StyleTabs = styled.ul`
  width:100%;
  min-height: 100%;
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  display: flex;
  transition: opacity 0.3s;
  &:before {
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
    content: '';
    pointer-events: none;
    left: 0;
    box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
  }
  li {
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    line-height: 21px;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
    color: #504E66;
    padding: .5rem 0;
    height: auto;
    border-bottom: 4px solid transparent;
    font-weight: 600;
    &.tab-active {
      color: #0071DC;
      border-bottom: 4px solid #0071DC !important;
    }
  }
`;
