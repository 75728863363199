import styled from 'styled-components';

export const StyleSidebar = styled.div`
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  transition: width 0.3s ease;
  flex-direction: column;
  position: fixed;
  top: 0px;
  bottom: 0px;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  .menu {
    .menu-item {
      .menu-link {
        color: #9D9DA6;
      }
    }
  }
  .app-sidebar-logo {
    padding-top: 36px;
    padding-bottom: 36px;
    // height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    flex-shrink: 0;
    border-bottom: 1px dashed #D2D2D2;
    color: #FFFFFF;
  }
`;