/* eslint-disable @typescript-eslint/no-explicit-any */

// utils
import Swal from 'sweetalert2';
import { instance } from '@utils/axios';
import dayjs from 'dayjs';

const UserService = {
  convertedAddressFormat: async (currentToken: any, id: any, type: any) => {
    if (id !== null) {
      const res = await instance.get(`/address/${type}/${id}`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      });

      const { data, status } = res;

      if (status === 200) {
        return data.data.name_th;
      }
    }

    return null;
  },
  createAccount: async (createData: any, currentToken: any) => {
    try {
      const res = await instance.post('/user/create', createData, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      });

      const { status } = res;

      if (status === 200) {
        return res;
      }
    } catch (error: any) {
      return error;
    }
  },
  checkEmail: async (email: any) => {
    try {
      const res = await instance.post(`/user/check-email`, {
        email,
      });

      const { status } = res;

      if (status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error: any) {
      return false;
    }
  },
  checkPhone: async (phone: any) => {
    try {
      const res = await instance.post(`/user/check-phone`, {
        phone,
      });

      const { status } = res;

      if (status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error: any) {
      return false;
    }
  },
  deleteUser: async (currentToken: any, userId: any) => {
    try {
      const res = await instance.delete(`/user/delete/${userId}`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      });

      const { status } = res;

      if (status === 200) {
        return true;
      }
    } catch (error) {
      console.log('error', error);
    }

    return false;
  },
  getUsers: async (currentToken: any, otp?: any, search?: any, page?: any) => {
    try {
      const res = await instance.post(
        '/user',
        {
          page: page || 1,
          limit: 50,
          sign_otp: otp || '',
          search: search || [],
          sort_type: 'desc',
          sort: 'createdAt',
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        },
      );
      const { data, status } = res;
      if (status === 200) {
        return { data: data.data.users, total: data.data.total };
      } else {
        const secRes = await instance.post(
          '/user',
          {
            page: page || 1,
            limit: 50,
            sign_otp: otp || '',
            search: search || [],
            sort_type: 'desc',
            sort: 'createdAt',
          },
          {
            headers: {
              Authorization: `Bearer ${currentToken}`,
            },
          },
        );
        if (secRes.status === 200) {
          return {
            data: secRes.data.data.users,
            total: secRes.data.data.total,
          };
        }
      }
    } catch (error) {
      console.log('error', error);
    }

    return false;
  },
  getAdmin: async (currentToken: any, otp?: any, search?: any, page?: any) => {
    try {
      const res = await instance.get('/user/admin/all', {
        params: {
          page: page || 1,
          limit: 20,
          moment: 'all',
          sign_otp: otp || '',
          search: search || '',
        },
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      });

      const { data, status } = res;
      if (status === 200) {
        return { data: data.data.users, total: data.data.total };
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        window.location.reload();
      }
      console.log('error', error);
    }

    return false;
  },
  getUserById: async (currentToken: any, userId: any) => {
    try {
      const res = await instance.get(`/user/${userId}`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      });

      const { data, status } = res;

      if (status === 200) {
        return data;
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        window.location.reload();
      }
      console.log('error', error);
    }

    return false;
  },
  signImg: async (currentToken: any, fileName: any) => {
    try {
      const res = await instance.post(
        '/file/signurl',
        {
          fileName,
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        },
      );

      const { data, status } = res;

      if (status === 200) {
        return data.url;
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        window.location.reload();
      }
      console.log('error', error);
    }

    return false;
  },
  setupProvinceArray: async (currentToken: any) => {
    try {
      const res = await instance.get('/address/province', {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      });

      const { data, status } = res;

      if (status === 200) {
        return data.data;
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        window.location.reload();
      }
      console.log('error', error);
    }

    return false;
  },
  setupDistrictArray: async (currentToken: any, provinceId: any) => {
    try {
      const res = await instance.get(
        `/address/district/province/${provinceId}`,
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        },
      );

      const { data, status } = res;

      if (status === 200) {
        return data.data;
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        window.location.reload();
      }
      console.log('error', error);
    }

    return false;
  },
  setupSubDistrictArray: async (currentToken: any, districtId: any) => {
    try {
      const res = await instance.get(
        `/address/subdistrict/district/${districtId}`,
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        },
      );

      const { data, status } = res;

      if (status === 200) {
        return data.data;
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        window.location.reload();
      }
      console.log('error', error);
    }

    return false;
  },
  updateData: async (currentToken: any, updateData: any, userId: any) => {
    try {
      const res = await instance.put(`/user/${userId}`, updateData, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      });
      const { status } = res;

      if (status === 200) {
        return true;
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        window.location.reload();
      }
      console.log(error);
      return Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: error?.message || 'แก้ไขไม่สำเร็จ',
        timer: 5000,
        showCancelButton: false,
        showConfirmButton: false,
      }).then(() => {
        return false;
      });
    }

    return false;
  },

  updatePasswordData: async (
    currentToken: any,
    updateData: any,
    userId: any,
  ) => {
    try {
      const res = await instance.put(
        `/user/${userId}/update-password`,
        updateData,
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        },
      );
      const { status } = res;

      if (status === 200) {
        return true;
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        window.location.reload();
      }
      console.log('updateData error', error);
    }

    return false;
  },
  uploadAvatar: async (currentToken: any, imageBase64: any) => {
    try {
      const res = await instance.post(
        '/file/user/avatar/upload',
        {
          imgData: imageBase64,
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        },
      );

      const { data, status } = res;

      if (status === 200) {
        return data.url;
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        window.location.reload();
      }
      console.log('uploadAvatar error', error);
    }

    return false;
  },
  userHistory: async (
    currentToken: any,
    firebaseUid: any,
    historyType: any,
  ) => {
    try {
      const res = await instance.get('/user/history/backend', {
        params: { type: historyType, uid: firebaseUid },
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      });
      const { status } = res;

      if (status === 200) {
        return { data: res.data.data, total: res.data.total };
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        window.location.reload();
      }
      console.log('updateData error', error);
    }

    return false;
  },
  getBranch: async () => {
    try {
      const res = await instance.get(
        '/branch/all?page=1&limit=5000&status=3',
        {},
      );

      const { data, status } = res;
      if (status === 200) {
        return data.data;
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        window.location.reload();
      }
      console.log('error', error);
    }

    return false;
  },
  getBranchbyUserId: async (contentId: any) => {
    try {
      const res = await instance.get(`/branch/${contentId}/user`);

      const { data, status } = res;
      if (status === 200) {
        return data;
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        window.location.reload();
      }
      console.log('error', error);
    }

    return false;
  },
  getStudentId: async (id: any) => {
    try {
      const res = await instance.get(`/user/student-id/${id}/user`);

      const { data, status } = res;
      if (status === 200) {
        return data.users;
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        window.location.reload();
      }
      console.log('convertDistrictFormat error', error);
    }

    return null;
  },
  generateStudentId: async (id: any, currentToken: any) => {
    try {
      const res = await instance.post(
        `/user/student-id/add/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        },
      );

      const { status } = res;
      if (status === 200) {
        return true;
      }
    } catch (error: any) {
      console.log('convertDistrictFormat error', error);
    }

    return null;
  },
  getBranchMemberById: async (contentId: any, page: any, limit: any) => {
    try {
      const res = await instance.get(
        `/branch/${contentId}/member/front?page=${page}&limit=${limit}`,
      );

      const { data, status } = res;

      if (status === 200) {
        return data.data;
      }
    } catch (error) {
      console.log('error', error);
    }

    return false;
  },
  getBranchById: async (contentId: any, getViews = 0) => {
    try {
      const res = await instance.get(
        `/branch/${contentId}/front?get_views=${getViews}`,
      );

      const { data, status } = res;

      if (status === 200) {
        return data.data;
      }
    } catch (error) {
      console.log('error', error);
    }

    return false;
  },
  getBranchMemberExportById: async (contentId: any) => {
    try {
      const res = await instance.get(
        `/branch/${contentId}/member/front/export`,
      );
      const { data, status } = res;
      if (status === 200) {
        return data.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },
  editBranch: async (id: any, doc: any, token: any) => {
    try {
      const res = await instance.put(`/branch/${id}/front`, doc, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { data, status } = res;
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.log('error', error);
    }

    return false;
  },
  uploadImage: async (imageBase64: any, currentToken: any) => {
    try {
      const res = await instance.post(
        '/file/content/img/upload',
        {
          imgFolder: 'branch',
          imgData: imageBase64,
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        },
      );

      const { data, status } = res;

      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.log('uploadImage error', error);
    }

    return null;
  },
  getAppLogs: async (
    page: any,
    date: any,
    username: any,
    menu_activity: any,
    currentToken: any,
    role: any,
  ) => {
    try {
      const res = await instance.get(
        '/activity/logs',
        {
          params: {
            page: page || 1,
            limit: 50,
            start_date: date ? dayjs(date[0]).format("YYYY-MM-DD"): '',
            end_date: date ? dayjs(date[1]).format("YYYY-MM-DD"): '',
            username: username || '',
            menu_activity: menu_activity || '',
            order: 'desc',
            role_id: role || '',
          },
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        },
      );
      const { data, status } = res;
      if (status === 200) {
        return { data: data.data.data, total: data.data.totals, menu_activity: data.data.menu_activity };
      }
    } catch (error) {
      console.log('error', error);
    }
    return false;
  },
};

export default UserService;
