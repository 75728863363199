/* eslint-disable @typescript-eslint/no-unused-vars */
import {ReactNode} from 'react';

type WithChildren = {
  children?: ReactNode
};

const Content = ({children}: WithChildren) => {
  return (
    <div id="app_content" className="app-content">
      <div id="app_content_container" className="app-container w-full">
        {children}
      </div>
    </div>
  );
};

export default Content;
