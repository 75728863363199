import styled from 'styled-components';

export const StyleFooter = styled.div`
  transition: left 0.3s ease, right 0.3s ease;
  display: block;
  background-color: #ffffff;
  border-top: #ffffff;
  @media only screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    height: 60px;
  }
`;