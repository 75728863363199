import styled from 'styled-components';

export const StyleToolbar = styled.div`
  height: auto;
  background-color: transparent;
  border-top: 0;
  border-bottom: 0;
  box-shadow: none;
  display: flex;
  align-items: stretch;
  .bullet {
    display: inline-block;
    background-color: #B5B5C3;
    border-radius: 6px;
    width: 8px;
    height: 4px;
    flex-shrink: 0;
  }
  .breadcrumb {
    display: flex;
    align-items: center;
    background-color: transparent;
    padding: 0;
    margin: 0;
    .breadcrumb-item {
      display: flex;
      align-items: center;
      padding-left: 0.5rem;
      color: #A1A5B7;
      &:after {
        content: '';
        display: flex;
        width: 8px;
        height: 2px;
        background-color: #B5B5C3;
        margin-left: 0.5rem;
        align-items: center;
        justify-content: center;
      }
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
        color: #2A2A3C;
        &:after {
          display: none;
        }
      }
    }
  }
  .breadcrumb-item {
    + .breadcrumb-item {
      padding-left: 0.5rem;
    }
  }
`;