/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import Avatar from '@/components/elements/Avatar';
import Card from '@/components/elements/Card';
import { FaRegTrashAlt } from 'react-icons/fa';
import Button from '@/components/elements/Button';
import Badge from '@/components/elements/Badge';
import { Tab } from '@/components/elements/Tabs';
import classNames from 'classnames';
import { StyleTabs } from './style';
import { instance } from '@/utils/axios';

interface TabsProps {
  label: string;
  key: string;
  value: string;
}

interface UserHeaderProps {
  roleId: number;
  avatarSrc: string;
  dataSourse: any;
  onDelete?: () => void;
  tabOverview?: React.ReactNode;
  tabEvent?: React.ReactNode;
  tabCourse?: React.ReactNode;
  tabOther?: React.ReactNode;
  tabsNav?: TabsProps[];
  rootTabsNavClassName?: string;
  sizesTab?: 'xs' | 'sm' | 'md' | 'lg';
  tabType?: 'lifted' | 'bordered' | 'boxed';
  studentId?: number;
}

const UserHeader = (props: UserHeaderProps) => {
  const {
    avatarSrc,
    roleId,
    studentId,
    dataSourse,
    onDelete,
    tabOverview,
    tabEvent,
    tabCourse,
    tabOther,
    tabsNav,
    rootTabsNavClassName,
    sizesTab,
    tabType,
  } = props;
  const [active, setActive] = useState<string>('tabOverview');
  const [roleData, setRoleData] = useState<any>([]); // NOSONAR


  const fetchDataRole = async () => {
    instance.get('/role').then((res) => {
      const { data } = res.data;
      setRoleData(data);
    });
  };

  const btnCls = classNames(
    'tab',
    {
      'tab-lifted': tabType === 'lifted',
      'tab-bordered': tabType === 'bordered',
      'tab-boxed': tabType === 'boxed',
      'tab-xs': sizesTab === 'xs',
      'tab-sm': sizesTab === 'sm',
      'tab-md': sizesTab === 'md',
      'tab-lg': sizesTab === 'lg',
    },
    rootTabsNavClassName,
  );

  const handleTabClick = (index: string) => {
    setActive(index);
  };

  useEffect(() => {
    fetchDataRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <>
      <Card className="bg-white" bodyStyle={{ paddingBottom: 0 }}>
        <div className="flex flex-wrap sm:flex-nowrap mb-4">
          <div className="mr-7 relative">
            <Avatar
              size={160}
              shape="square"
              src={
                <img
                  src={avatarSrc ?? '/user-default.webp'}
                  alt={dataSourse?.full_name ?? `user avatar ${studentId}`}
                />
              }
            />
            <span className="absolute inset-x-0 mx-auto text-center bottom-5">
              <Badge
                shape="circular"
                content={
                  dataSourse?.active_status === 1
                    ? '(ใช้งานได้)'
                    : '  (ไม่ให้ใช้งานได้)'
                }
                color={dataSourse?.active_status === 1 ? 'success' : 'error'}
              />
            </span>
          </div>
          <div className="w-full flex justify-between items-start">
            <div className="w-full grow">
              <div className="flex items-center">
                <h2 className="text-gray-900 text-xl font-bold" data-testid='user-full-name' >
                  {dataSourse?.full_name}
                </h2>
              </div>
              <div className="text-muted pr-2 space-y-2">
                <p>{dataSourse?.firebase_email}</p>
                <p>
                  {dataSourse?.phone}
                  {dataSourse?.phone !== null && (
                    <span
                      className={
                        dataSourse?.sign_otp === 1
                          ? 'text-success ml-2'
                          : 'text-error ml-2'
                      }
                    >
                      {dataSourse?.sign_otp === 1
                        ? '(ใช้งานแอพได้)'
                        : '  (ใช้งานแอพไม่ได้)'}
                    </span>
                  )}
                </p>
                <p>ประเภทสมาชิก: {roleId === 1 ? 'ผู้ดูแลสูงสุด' : roleData.find(
                  (item: any) => item.id === roleId,
                )?.role_th
                }</p>
                {dataSourse?.user_branches?.length > 0 ? dataSourse?.user_branches?.map((item: any) => {
                  if (item?.status === 1) {
                    return <p key={item?.id}>แอดมินสาขา: {item?.branch?.name}</p>;
                  }
                  if (item?.status === 3) {
                    return <p key={item?.id}>สาขาที่สังกัด: {item?.branch?.name}</p>;
                  }
                  return (
                    <p key={item?.id}>สาขาที่สังกัด: -</p>
                  );
                }) : null}
              </div>
            </div>
            <div className="flex space-x-3 flex-none">
              <Button
                icon={<FaRegTrashAlt />}
                size="sm"
                color="error"
                onClick={onDelete}
              >
                ลบข้อมูล
              </Button>
            </div>
          </div>
        </div>
        <nav>
          <StyleTabs className="tabs nav-tabs gap-8">
            {tabsNav?.map((item) => {
              const { label, key, value } = item;
              const tabsKey = label.toString().toLowerCase().replace(' ', '-');
              return (
                <li
                  key={tabsKey}
                  aria-hidden="true"
                  className={classNames(btnCls, {
                    [`tab-active`]: active === key,
                  })}
                  onClick={() => handleTabClick(key)}
                >
                  {label ?? value}
                </li>
              );
            })}
          </StyleTabs>
        </nav>
      </Card>

      <Card className="bg-white" bodyStyle={{ paddingBottom: 0 }}>
        <Tab label="ข้อมูลส่วนตัว" active={active === 'tabOverview'}>
          {tabOverview}
        </Tab>
        <Tab label="กิจกรรมที่เข้าร่วม" active={active === 'tabEvent'}>
          {tabEvent}
        </Tab>
        <Tab label="คอร์สอบรมที่เข้าร่วม" active={active === 'tabCourse'}>
          {tabCourse}
        </Tab>
        <Tab label="การทำสมาธิที่เข้าร่วม" active={active === 'tabOther'}>
          {tabOther}
        </Tab>
      </Card>
    </>
  );
};

export default UserHeader;
